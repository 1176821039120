@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply leading-[normal] m-0;
  @apply bg-background;
}
*,
::before,
::after {
  border-width: 0;
}
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.top-glow {
  -webkit-filter: blur(500px);
  filter: blur(500px);
  border-radius: 50%;
  background-color: var(--primary-inside);
  border: 1px solid var(--stroked);
  box-sizing: border-box;
  width: 40%;
  height: 0;
  padding-bottom: 20%;
  margin-bottom: -12%;
  margin-top: -6%;
  margin-left: auto;
  margin-right: auto;
}

.middle-glow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-40%); /* Center the glow horizontally */
  bottom: 0;
  -webkit-filter: blur(500px);
  filter: blur(250px); /* Adjusted for better control */
  border: 1px solid var(--stroked);
  background-color: var(--primary-inside);
  border-radius: 50%;
  width: 30%; /* Adjust width as needed */
  padding-bottom: 20%; /* Match aspect ratio */
  z-index: -1; /* Adjust based on stacking needs */
}

.right-glow {
  filter: blur(896.4px);
  /* Add vendor prefixes for compatibility */
  filter: -webkit-blur(896.4px);
  filter: -moz-blur(896.4px);
  filter: -o-blur(896.4px);
  filter: -ms-blur(896.4px);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.25rem;
  height: 3.375rem;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background: var(--Stroked, #6381fc);
}

/* Custom scrollbar for Firefox */
.custom-scrollbar {
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: var(--Stroked, #6381fc) transparent; /* Thumb and track color */
}

/* Firefox-specific pseudo-elements (limited control) */
.custom-scrollbar::-moz-scrollbar {
  width: 0.25rem;
  height: 3.375rem;
}

.custom-scrollbar::-moz-scrollbar-trac k {
  background: var(--gray-600, rgba(255, 255, 255, 0.09));
}

.custom-scrollbar::-moz-scrollbar-thumb {
  border-radius: 0.25rem;
  background: var(--Stroked, #6381fc);
}
/* Add custom styles for radio buttons */
.custom-radio {
  appearance: none;
  background-color: none;
  border: 1px solid #d1d5db;
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
  display: inline-block;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
}

.custom-radio:checked {
  background-color: none;
  border-color: #6381fc;
}

.custom-radio:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #6381fc;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}
